import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Page } from 'components';
import { CreatePlantForm } from './components';
import { EditPageContext } from './wrappers/EditContext';

export const Edit: React.FC = () => {
  const { pathname } = useLocation();
  const [to, setTo] = useState<string>();
  const [hero, setHero] = useState<string>();

  const uuid = pathname.split('/')[2];

  if (to) return <Redirect push to={to} />;

  return (
    <Page title='Edit plant' hero={hero}>
      <div style={{ margin: 16 }}>
        <EditPageContext.Provider value={{ setHero }}>
          <CreatePlantForm uuid={uuid} onComplete={(): void => setTo('/')} />
        </EditPageContext.Provider>
      </div>
    </Page>
  );
};
