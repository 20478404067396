import React from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { createTheme, lightThemePrimitives } from 'baseui/themes';
import { colors } from 'baseui/tokens';

const myThemePrimitives = {
  ...lightThemePrimitives,
  primary: colors.red400,
  primary50: colors.red50,
  primary100: colors.red100,
  primary200: colors.red200,
  primary300: colors.red300,
  primary400: colors.red400,
  primary500: colors.red500,
  primary600: colors.red600,
  primary700: colors.red700,
};
const myTheme = createTheme(myThemePrimitives);

const engine = new Styletron();

export const ThemeWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <StyletronProvider value={engine}>
    <BaseProvider theme={myTheme}>{children}</BaseProvider>
  </StyletronProvider>
);
