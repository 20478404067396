import React, { useState, useContext } from 'react';
import { FormControl } from 'baseui/form-control';
import { Notification, KIND } from 'baseui/notification';
import { Textarea } from 'baseui/textarea';
import { Button } from 'baseui/button';
import { Input } from 'baseui/input';
import { Page } from 'components';
import { put } from 'hooks/useFetch';
import { SecurityContext } from 'wrappers/SecurityContext';

export const Feedback: React.FC = () => {
  const { Kargo4 } = useContext(SecurityContext);

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [showValidate, setShowValidate] = useState(false);

  async function submit(): Promise<void> {
    setShowValidate(true);
    if (!body || !title) return;

    setLoading(true);
    try {
      const { error: err } = await put<{ error?: string }>(
        '/api/feedback',
        Kargo4 as string,
        { title, body },
      );
      if (err) throw new Error(err);
      setDone(true);
    } catch (ex) {
      setError(ex);
    }
    setLoading(false);
  }

  return (
    <Page title='Send Feedback'>
      {error && (
        <Notification kind={KIND.negative}>{error?.message}</Notification>
      )}

      {done ? (
        <Notification>Feedback submitted! Thank you</Notification>
      ) : (
        <>
          If something&apos;s wrong, please describe it here.
          <FormControl
            label='Title'
            // caption="Describe what's wrong"
            error={showValidate && !body}
          >
            <Input
              disabled={loading}
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                setTitle(e.target.value)
              }
              size='large'
            />
          </FormControl>
          <FormControl
            label='Description'
            caption="Describe what's wrong"
            error={showValidate && !body}
          >
            <Textarea
              disabled={loading}
              value={body}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void =>
                setBody(e.target.value)
              }
              size='large'
            />
          </FormControl>
          <Button onClick={submit} isLoading={loading}>
            Submit
          </Button>
        </>
      )}
    </Page>
  );
};
