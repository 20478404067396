import React, { useState } from 'react';
import QrReader from 'react-qr-reader';
import { Notification, KIND } from 'baseui/notification';
import { useNFC } from 'components';
import { QR_PREFIX } from '../../../constants';
import classes from './Scan.module.scss';

export const Scan: React.FC<{ onComplete: (uuid: string) => void }> = ({
  onComplete,
}) => {
  const [error, setError] = useState<Error>();

  // will do nothing if NFC is not supported
  useNFC(onComplete, setError);

  const handleScan = (data: string | null): void => {
    if (!data) return;

    if (!data.startsWith(QR_PREFIX)) {
      setError(new Error("That QR code isn't a plant"));
      return;
    }
    onComplete(data.slice(QR_PREFIX.length));
  };

  const nfcSupported = typeof window.NDEFReader === 'function';

  return (
    <>
      To view or add a plant, scan it&apos;s QR Code
      {nfcSupported && ' or tap your phone onto the NFC tag'}.
      <br />
      <br />
      {error && (
        <Notification kind={KIND.negative}>{error.message}</Notification>
      )}
      <QrReader
        delay={500}
        className={classes.qrReader}
        onError={setError}
        onScan={handleScan}
      />
    </>
  );
};
