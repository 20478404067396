import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Home, Print, Add, Edit, GroupView, Feedback } from 'routes';
import { ThemeWrapper } from 'wrappers/theme';
import { SecurityContext } from 'wrappers/SecurityContext';
import { SecurityWrapper } from 'wrappers/auth';
import { Login } from 'components';

const RouterOrLoginPage: React.FC = () => {
  const { Kargo4 } = useContext(SecurityContext);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/print' component={Print} />
        {Kargo4 ? (
          <>
            <Route exact path='/add' component={Add} />
            <Route exact path='/g/:groupName' component={GroupView} />
            <Route exact path='/p/:id' component={Edit} />
            <Route exact path='/feedback' component={Feedback} />
            <Route exact path='/' component={Home} />
            {/* TODO: add back 404 page */}
          </>
        ) : (
          <Route>
            <Login />
          </Route>
        )}
      </Switch>
    </BrowserRouter>
  );
};

export const App: React.FC = () => {
  return (
    <ThemeWrapper>
      <SecurityWrapper>
        <RouterOrLoginPage />
      </SecurityWrapper>
    </ThemeWrapper>
  );
};
