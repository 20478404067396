import React, { useContext } from 'react';
import { Button } from 'baseui/button';
import { SecurityContext } from 'wrappers/SecurityContext';

export const Login: React.FC = () => {
  const { login } = useContext(SecurityContext);
  return (
    <div style={{ margin: 32 }}>
      <div style={{ marginBottom: 8 }}>You need to Login</div>
      <Button onClick={login}>Login</Button>
    </div>
  );
};
