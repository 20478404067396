import React from 'react';
import { Card, StyledBody } from 'baseui/card';
import { Link } from 'react-router-dom';
import { Plant } from 'types';
import { LazyImage } from 'components';

export const PlantPreviewCard: React.FC<{ plant: Plant }> = ({ plant }) => {
  return (
    <Link
      to={`/p/${plant.id}`}
      style={{ cursor: 'pointer', margin: 12, display: 'block' }}
    >
      <Card>
        <StyledBody>
          <h4>{plant.name}</h4>
          {plant.images?.map((img) => {
            const urn = typeof img === 'string' ? img : img.urn;
            return <LazyImage src={urn} key={urn} />;
          })}
        </StyledBody>
      </Card>
    </Link>
  );
};
