import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Notification } from 'baseui/notification';
import { Card, StyledBody } from 'baseui/card';
import { Button } from 'baseui/button';
import { Grid, Cell } from 'baseui/layout-grid';
import { Input } from 'baseui/input';
import { Page, SearchBefore } from 'components';
import { useFetch } from 'hooks/useFetch';
import { Plant, PlantList, UserMeta } from 'types';
import { PlantPreviewCard } from '../groups';
import { GroupCard } from './components';

const listToGroups = (list: Plant[] = []): Record<string, Plant[]> => {
  const out: Record<string, Plant[]> = {};
  list.forEach((p) => {
    p.group.forEach((group) => {
      if (!out[group]) out[group] = [];
      out[group].push(p);
    });
  });
  return out;
};

export const Home: React.FC = () => {
  const [plantsList, error] = useFetch<PlantList>('/api/record');
  const [userData, error2] = useFetch<UserMeta>('/api/user-data');
  const [search, setSearch] = useState('');

  const groups = listToGroups(plantsList?.plants);
  const plantsToShow = search
    ? plantsList?.plants.filter((x) =>
        Object.values(x)
          .flat()
          .join('|')
          .toLowerCase()
          .includes(search.toLowerCase()),
      )
    : undefined;

  return (
    <Page title='My Plants'>
      {error && <Notification kind='negative'>{error.message}</Notification>}
      {error2 && <Notification kind='negative'>{error2.message}</Notification>}

      <Link to='/add'>
        <Button
          overrides={{
            BaseButton: { style: { width: '100%' } },
          }}
        >
          Scan a plant
        </Button>
      </Link>

      <div style={{ margin: '12px 0px' }}>
        <Input
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            setSearch(e.target.value)
          }
          overrides={{ Before: SearchBefore }}
          placeholder='Search all plants'
          error={search ? !plantsToShow?.length : false}
        />
      </div>

      <Grid
        overrides={{
          Grid: {
            // style: { overflowY: 'scroll', height: 'calc(100vh - 275px)' },
          },
        }}
      >
        {plantsToShow
          ? plantsToShow?.map((p) => (
              <Cell
                key={p.id}
                span={[4, 4, 6]}
                overrides={{ Cell: { style: { height: 'fit-content' } } }}
              >
                <PlantPreviewCard plant={p} />
              </Cell>
            ))
          : Object.keys(groups).map((groupName) => (
              <Cell key={groupName} span={[4, 4, 6]}>
                <GroupCard
                  groupName={groupName}
                  image={userData?.groups?.[groupName]?.image}
                />
              </Cell>
            ))}

        {plantsToShow && !plantsToShow.length && (
          <Card>
            <StyledBody>
              You don&apos;t have any{' '}
              {search ? 'matching plants' : 'plants yet'}.
            </StyledBody>
          </Card>
        )}
      </Grid>
    </Page>
  );
};
