import React, { useState, useContext } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { Button } from 'baseui/button';
import { Notification } from 'baseui/notification';
import { SecurityContext } from 'wrappers/SecurityContext';
import { authedFetch } from 'hooks/useFetch';

type E =
  | { type: 'plant'; plantID: string; onDelete?: () => void }
  | {
      type: 'image';
      plantID: string;
      urn: string;
      onDelete?: (urn: string) => void;
    };

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const DeleteButton: React.FC<E> = ({ type, plantID, urn, onDelete }) => {
  const { Kargo4 } = useContext(SecurityContext);

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  async function onClick(): Promise<void> {
    setLoading(true);
    try {
      const path = `/api/record${type === 'image' ? '/img' : ''}`;
      await authedFetch(path, Kargo4 as string, {
        method: 'DELETE',
        body: { plantID, urn },
      });
      setIsOpen(false);
      if (onDelete) onDelete(type === 'image' ? urn : undefined);
    } catch (ex) {
      setError(`Failed to delete ${type}: ${ex.message}`);
    }
    setLoading(false);
  }

  return (
    <>
      <Modal
        onClose={(): void => setIsOpen(false)}
        closeable
        isOpen={isOpen}
        animate
        autoFocus
        size='default'
        role='dialog'
        unstable_ModalBackdropScroll
      >
        <ModalHeader>Delete {type}</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this {type}?
          {error && <Notification kind='negative'>{error}</Notification>}
        </ModalBody>
        <ModalFooter>
          <ModalButton kind='tertiary' onClick={(): void => setIsOpen(false)}>
            Cancel
          </ModalButton>
          <ModalButton onClick={onClick} isLoading={loading}>
            Delete
          </ModalButton>
        </ModalFooter>
      </Modal>
      <Button onClick={(): void => setIsOpen(true)} kind='tertiary'>
        Delete
      </Button>
    </>
  );
};
