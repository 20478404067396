import React, { useState, useEffect, useContext } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';

import { useStyletron } from 'baseui';
import { ArrowLeft } from 'baseui/icon';
import { Button } from 'baseui/button';
import { Grid, Cell } from 'baseui/layout-grid';
import { Input } from 'baseui/input';
import { Card, StyledBody } from 'baseui/card';
import { Notification } from 'baseui/notification';
import { StyledSpinnerNext as Spinner } from 'baseui/spinner';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';

import { PlantList, Plant, UserMeta } from 'types';
import { Page, SearchBefore, getBlobUrl, LazyImage } from 'components';
import { useFetch, put } from 'hooks/useFetch';
import { SecurityContext } from 'wrappers/SecurityContext';
import { PlantPreviewCard } from './PlantPreviewCard';

export const GroupPhotoPicker: React.FC<{
  groupName: string;
  plants: Plant[];
  userData: UserMeta;
  onClose: () => void;
}> = ({ groupName, plants, userData, onClose }) => {
  const { Kargo4 } = useContext(SecurityContext);

  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  async function onSelect(urn: string): Promise<void> {
    setLoading(true);
    try {
      const { error: up } = await put('/api/user-data', Kargo4 as string, {
        userData: {
          ...userData,
          groups: {
            ...userData.groups,
            [groupName]: {
              ...userData.groups?.image,
              image: urn,
            },
          },
        } as UserMeta,
      });
      if (up) throw up; // ha ha
      onClose();
    } catch (ex) {
      setError(ex);
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen
      onClose={onClose}
      closeable
      animate
      autoFocus
      size='default'
      unstable_ModalBackdropScroll
    >
      <ModalHeader>Select photo for {groupName}</ModalHeader>
      <ModalBody>
        You can select a photo for this group to be shown on the home page.
        {error && <Notification kind='negative'>{error.message}</Notification>}
        {loading ? (
          <Spinner />
        ) : (
          plants
            .flatMap((x) => x.images)
            .map((img) => {
              const urn = typeof img === 'string' ? img : img.urn;
              return (
                <span
                  onClick={(): Promise<void> => onSelect(urn)}
                  role='button'
                  tabIndex={0}
                  key={urn}
                >
                  <LazyImage src={urn} dateTaken={img.dateTaken} />
                </span>
              );
            })
        )}
      </ModalBody>
      <ModalFooter>
        <ModalButton kind='tertiary' onClick={onClose}>
          Cancel
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export const GroupView: React.FC = () => {
  const { pathname } = useLocation();
  const groupName = pathname.split('/')[2];

  const { Kargo4 } = useContext(SecurityContext);

  const [plantsList, error] = useFetch<PlantList>('/api/record');
  const [userData, error2] = useFetch<UserMeta>('/api/user-data');

  const [to, setTo] = useState<string>();
  const [hero, setHero] = useState<string>();
  const [search, setSearch] = useState<string>();
  const [pickerOpen, setPickerOpen] = useState(false);

  const [, theme] = useStyletron();

  const plants = plantsList?.plants.filter((x) => x.group.includes(groupName));
  const plantsToShow = search
    ? plants?.filter((x) =>
        Object.values(x)
          .flat()
          .join('|')
          .toLowerCase()
          .includes(search.toLowerCase()),
      )
    : plants;

  useEffect(() => {
    if (error || error2 || plants?.length === 0) setTo('/');
  }, [plants, error, error2]);

  useEffect(() => {
    const urn = userData?.groups?.[groupName]?.image;
    if (!urn) return;
    getBlobUrl(urn, Kargo4 as string)
      .then(setHero)
      .catch(console.warn);
  }, [userData, groupName, Kargo4]);

  if (to) return <Redirect push to={to} />;

  return (
    <Page title='My Plants' hero={hero}>
      <h2 style={{ marginBottom: 12 }}>
        {pickerOpen && plants && userData && (
          <GroupPhotoPicker
            groupName={groupName}
            plants={plants}
            userData={userData}
            onClose={(): void => setPickerOpen(false)}
          />
        )}
        <Grid>
          <Cell span={[4, 4, 6]}>
            <Link to='/'>
              <ArrowLeft
                size={36}
                overrides={{
                  Svg: {
                    style: {
                      verticalAlign: 'top',
                      color: theme.colors.primary,
                    },
                  },
                }}
              />
            </Link>{' '}
            {groupName}
          </Cell>
          <Cell span={[4, 4, 6]}>
            <Link to='/add' style={{ float: 'right', marginTop: -4 }}>
              <Button>Scan a plant</Button>
            </Link>
            <span style={{ float: 'right', marginTop: -4 }}>
              <Button
                kind='tertiary'
                onClick={(): void => setPickerOpen(true)}
                disabled={!plants || !userData}
              >
                Pick photo
              </Button>
            </span>
          </Cell>
        </Grid>
      </h2>

      <div style={{ margin: '12px 0px' }}>
        <Input
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            setSearch(e.target.value)
          }
          overrides={{ Before: SearchBefore }}
          placeholder='Search all plants'
          error={search ? !plantsToShow?.length : false}
        />
      </div>

      <Grid>
        {!plantsToShow?.length && (
          <Card>
            <StyledBody>
              You don&apos;t have any{' '}
              {search ? 'matching plants' : 'plants yet'}.
            </StyledBody>
          </Card>
        )}
        {plantsToShow?.map((p) => (
          <Cell key={p.id} span={[4, 4, 6]}>
            <PlantPreviewCard plant={p} />
          </Cell>
        )) || 'Loading...'}
      </Grid>
    </Page>
  );
};
