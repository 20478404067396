import React, { useContext, useState, useEffect } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { Hide, Upload, Show, Plus, Alert } from 'baseui/icon';
import { Layer } from 'baseui/layer';
import {
  Unstable_AppNavBar as AppNavBar,
  MainNavItemT,
  UserNavItemT,
} from 'baseui/app-nav-bar';
import { SecurityContext } from 'wrappers/SecurityContext';
import { useFetch } from 'hooks/useFetch';
import { PlantList } from 'types';
import classes from './Page.module.scss';

type Item = { label: string; id?: string; path?: string };

const renderItem = (item: Item): string => item.label;

const MAIN_NAV: MainNavItemT[] = [
  {
    icon: Show,
    item: { label: 'Home', id: 'home', path: /^\/$/ },
    mapItemToNode: renderItem,
    mapItemToString: renderItem,
  },
  {
    icon: Plus,
    item: { label: 'Scan plant', id: 'add', path: /\/(edit|add)/ },
    mapItemToNode: renderItem,
    mapItemToString: renderItem,
  },
  {
    icon: Upload,
    item: { label: 'Print Codes', id: 'print', path: /^\/print/ },
    mapItemToNode: renderItem,
    mapItemToString: renderItem,
  },
  {
    icon: Alert,
    item: { label: 'Send Feedback', id: 'feedback', path: /^\/feedback/ },
    mapItemToNode: renderItem,
    mapItemToString: renderItem,
  },
];
const USER_NAV: UserNavItemT[] = [
  {
    icon: Hide,
    item: { label: 'Logout', id: 'logout' },
    mapItemToNode: renderItem,
    mapItemToString: renderItem,
  },
];

const HeroImage: React.FC<{ src: string }> = ({ src }) => {
  return (
    <div className={classes.heroImage}>
      <img src={src} alt='Plant' />
    </div>
  );
};

export const Page: React.FC<{ title: string; hero?: string }> = ({
  hero,
  children,
}) => {
  const { pathname } = useLocation();
  const [plantsList] = useFetch<PlantList>('/api/record');

  const { payload, logout } = useContext(SecurityContext);
  const [to, setTo] = useState<string>();

  const onClick = (item: { item: { item: Item } }): void => {
    switch (item.item.item.id) {
      case 'logout':
        return logout();
      case 'home':
        return setTo('/');
      case 'add':
        return setTo('/add');
      case 'print':
        return setTo('/print');
      case 'feedback':
        return setTo('/feedback');
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (to) setTo(undefined);
  }, [to]);

  if (to) return <Redirect push to={to} />;

  return (
    <>
      {hero && <HeroImage src={hero} />}
      <div className={`${classes.page} ${hero && classes.pageWithHero}`}>
        {payload.email && (
          <Layer>
            <div className={classes.navbar}>
              <AppNavBar
                appDisplayName='🌷 Plant App'
                mainNav={MAIN_NAV.map((x) => ({
                  ...x,
                  active: x.item.path?.test(pathname),
                }))}
                onNavItemSelect={onClick}
                userNav={USER_NAV}
                appDisplayNameLink='/'
                username={payload.email}
                usernameSubtitle={
                  plantsList?.plants
                    ? `🌻 ${plantsList?.plants.length} Plants`
                    : undefined
                }
                userImgUrl={payload.picture}
              />
            </div>
          </Layer>
        )}
        {children}
      </div>
    </>
  );
};
