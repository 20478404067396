import { createContext } from 'react';
import { KPayload } from 'types';

interface TSecurityContext {
  Kargo4: string | undefined;
  login: () => void;
  logout: () => void;
  payload: KPayload;
}

// dumb defaults to keep TS happy
export const SecurityContext = createContext<TSecurityContext>({
  Kargo4: undefined,
  login: () => undefined,
  logout: () => undefined,
  payload: {
    email: '',
    picture: '',
    token: '',
  },
});
