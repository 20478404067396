import React from 'react';
import { Card, StyledBody } from 'baseui/card';
import { Link } from 'react-router-dom';
import { LazyImage } from 'components';
import classes from './GroupCard.module.scss';

const PlantEmoji: React.FC = () => (
  <span role='img' aria-label='Flower' className={classes.big}>
    💐
  </span>
);

export const GroupCard: React.FC<{ groupName: string; image?: string }> = ({
  groupName,
  image,
}) => {
  return (
    <Link to={`/g/${groupName}`} className={classes.link}>
      <Card>
        <StyledBody>
          <div className={classes.centre}>
            {image ? <LazyImage src={image} /> : <PlantEmoji />}
            <br />
            <br />
            <span className={classes.kindaBig}>{groupName}</span>
          </div>
        </StyledBody>
      </Card>
    </Link>
  );
};
