import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';
import { unregister } from './sw';

import './style/index.css';

ReactDOM.render(<App />, document.getElementById('root'));

unregister(); // TODO: enable cautiously (i.e. not /api/**)
