import React, { useState, useContext, useEffect } from 'react';
import { authedFetch } from 'hooks/useFetch';
import { Option, Value, Select } from 'baseui/select';
import { SecurityContext } from 'wrappers/SecurityContext';

// Value === Option[] lol

const cache = new Map<string, Option[]>();

type GetLabel = React.FC<{ option?: Option }>;
const getValueLabel: GetLabel = ({ option }) => <>🌵 {option?.label}</>;
const getOptionLabel: GetLabel = ({ option }) => (
  <>
    {option?.label}
    {option?.description && (
      <>
        <br />
        <small>{option?.description}</small>
      </>
    )}
  </>
);

export const AutoCompleteInput: React.FC<{
  value: Value;
  setValue: (_: Value) => void;
}> = ({ value, setValue }) => {
  const { Kargo4 } = useContext(SecurityContext);

  const [search, setSearch] = useState<string>();
  const [options, setOptions] = useState<Option[]>([
    { id: '-', label: 'Start typing to search', disabled: true },
  ]);

  useEffect(() => {
    if (!search) return (): void => undefined;

    setOptions([
      { id: '-', label: `Searching for '${search}'...`, disabled: true },
    ]);

    const controller = new AbortController();

    // user probably hit backspace
    const cached = cache.get(search);
    if (cached) {
      setOptions(cached);
      return (): void => undefined;
    }

    const debounce = setTimeout(async () => {
      const { results } = await authedFetch<{ results: Option[] }>(
        '/api/database/query',
        Kargo4 as string,
        { method: 'POST', body: { search }, signal: controller.signal },
      );
      cache.set(search, results);

      setOptions(results);
    }, 400);

    return (): void => {
      clearTimeout(debounce);
      controller.abort();
    };
  }, [Kargo4, search]);

  return (
    <Select
      options={options}
      isLoading={options[0]?.label === 'Loading...'}
      value={value}
      type='search'
      onInputChange={(e): void => setSearch(e.currentTarget.value)}
      onChange={(params): void => setValue(params.value)}
      getValueLabel={getValueLabel}
      getOptionLabel={getOptionLabel}
      placeholder='Search for species'
    />
  );
};
