import React, { useState, useContext, useEffect } from 'react';

import { useStyletron } from 'baseui';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Textarea } from 'baseui/textarea';
import { Select, Value } from 'baseui/select';
import { Datepicker } from 'baseui/datepicker';
import { Button } from 'baseui/button';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { Notification } from 'baseui/notification';

import { PlantList, Plant, ServerPlant } from 'types';
import { useFetch, put, getSpecific, authedFetch } from 'hooks/useFetch';
import { SecurityContext } from 'wrappers/SecurityContext';
import { getBlobUrl } from 'components';
import { blobUrlToBase64, PhotoInput } from './FileUpload';
import { DeleteButton } from './DeleteButton';
import { AutoCompleteInput } from './AutoCompleteInput';
import { PhotoList } from './formTypes';
import { EditPageContext } from '../wrappers';

const UNGROUPED = 'Ungrouped';

const transformGroups = (x: string[]): { id: string }[] =>
  x.filter((id) => id !== UNGROUPED).map((id) => ({ id }));

interface CreatePlantFormProps {
  uuid: string;
  onComplete: () => void;
}
export const CreatePlantForm: React.FC<CreatePlantFormProps> = ({
  uuid,
  onComplete,
}) => {
  const [plantsList, fetchError] = useFetch<PlantList>('/api/record');
  const [, theme] = useStyletron();

  const { setHero } = useContext(EditPageContext);
  const { Kargo4, payload } = useContext(SecurityContext);

  const [showValidate, setShowValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  // form items
  const [name, setName] = useState('');
  const [species, setSpecies] = useState<Value>([]);
  const [price, setPrice] = useState<number | ''>('');
  const [source, setSource] = useState('');
  const [date, setDate] = useState<Date[]>([]);
  const [notes, setNotes] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [photos, setPhotos] = useState<PhotoList[]>([]);
  const [groups, setGroups] = useState<Value>([]);

  // not part of the form, just for diplay
  const [owners, setOwners] = useState<string[]>([]);
  const [dbDesc, setDbDesc] = useState<string>();

  const existing = plantsList?.plants.find((x) => x.id === uuid);
  const existingGroups = [
    ...new Set(plantsList?.plants.flatMap((x) => x.group) || []),
  ];

  useEffect(() => {
    setHero(photos?.[0]?.blobUrl);
    return (): void => setHero(undefined);
  });

  useEffect(() => {
    async function autofillExisting(P: Plant): Promise<void> {
      setName(P.name);
      setIsPublic(P.isPublic);
      if (P.owners) setOwners(P.owners);
      if (P.price) setPrice(P.price);
      if (P.source) setSource(P.source);
      if (P.date) setDate([new Date(P.date)]);
      if (P.notes) setNotes(P.notes);
      setGroups(transformGroups(P.group));

      if (P.plantDbId) {
        type E = { meta: Value; dump: { stats: string[]; text: string } };
        authedFetch<E>('/api/database/get', Kargo4 as string, {
          method: 'POST',
          body: { plantDbId: P.plantDbId },
        })
          .then(({ meta, dump }) => {
            setSpecies([meta]);
            setDbDesc(dump.text);
          })
          .catch(setError);
      }

      const p = P.images?.map(async (urnOrObj) => {
        const { urn, dateTaken } =
          typeof urnOrObj === 'string'
            ? { urn: urnOrObj, dateTaken: undefined } // legacy
            : urnOrObj;
        return {
          blobUrl: await getBlobUrl(urn, Kargo4 as string),
          urn,
          dateTaken,
        };
      }) as Promise<PhotoList>[];
      if (p) Promise.all(p).then(setPhotos).catch(setError);
    }
    if (!existing) return;
    // TODO: auto complete fields
    // const existinSourcesForAC = uniq(
    //   plantsList.plants.map((x) => x.source).filter(Boolean),
    // );
    // console.log({ existinSourcesForAC });
    if (existing) {
      autofillExisting(existing);
    } else {
      // this plant might belong to someone else and be public
      getSpecific(uuid, Kargo4 as string)
        .then((plant) => plant && autofillExisting(plant))
        .catch(setError);
    }
  }, [Kargo4, existing, uuid]);

  async function save(): Promise<void> {
    try {
      const isInvalid = !name;
      if (isInvalid) {
        setShowValidate(true);
        return;
      }
      setLoading(true);

      const newImages = await Promise.all(
        photos
          .filter(({ urn }) => !urn)
          .map(async ({ blobUrl, dateTaken }) => ({
            b64: await blobUrlToBase64(blobUrl),
            dateTaken,
          })),
      );

      const plant: ServerPlant = {
        type: 'plant',
        id: uuid,
        name,
        group: groups.length ? groups.map((x) => x.id as string) : [UNGROUPED],
        newImages,
        notes,
        date: date[0]?.toISOString(),
        price: price || undefined,
        isPublic,
        source,
        plantDbId: species[0].id as string,
      };
      await put('/api/record', Kargo4 as string, { plant });

      onComplete();
    } catch (ex) {
      setError(new Error(`Failed to save plant (${ex.message})`));
      setLoading(false);
    }
  }

  // this doesn't work
  // const saveTagOnBlur = (): void =>
  //   document.querySelector('ul[role="listbox"] > li')?.click();

  return (
    <div>
      {(error || fetchError) && (
        <Notification kind='negative'>
          {fetchError?.message || error?.message}
        </Notification>
      )}
      {showValidate && (
        <Notification kind='negative'>
          Some required fields are not filled out.
        </Notification>
      )}

      <FormControl label='Plant Name' error={showValidate && !name}>
        <Input
          disabled={loading}
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            setName(e.target.value)
          }
        />
      </FormControl>

      <FormControl label='Species'>
        <AutoCompleteInput value={species} setValue={setSpecies} />
      </FormControl>

      <FormControl label='Price'>
        <Input
          disabled={loading}
          type='number'
          min={0}
          startEnhancer='$'
          value={price}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            setPrice(e.target.value === '' ? '' : +e.target.value)
          }
        />
      </FormControl>

      <FormControl label='Date of acquisition'>
        {/* TODO: the native picker might have better UX on mobile */}
        <Datepicker
          disabled={loading}
          value={date}
          onChange={({ date: d }): void => setDate(Array.isArray(d) ? d : [d])}
          formatString='dd/MM/yyyy'
          placeholder='Date'
        />
      </FormControl>

      <FormControl label='Source'>
        <Input
          disabled={loading}
          value={source}
          placeholder='Where you bought the plant'
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            setSource(e.target.value)
          }
        />
      </FormControl>

      <FormControl label='Group'>
        <Select
          disabled={loading}
          creatable
          multi
          options={transformGroups(existingGroups)}
          labelKey='id'
          valueKey='id'
          onChange={({ value }): void => setGroups(value)}
          value={groups}
          onBlurResetsInput
          onCloseResetsInput
          // onBlur={saveTagOnBlur}
        />
      </FormControl>

      <FormControl label='Notes'>
        <Textarea
          disabled={loading}
          value={notes}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void =>
            setNotes(e.target.value)
          }
          size='large'
        />
      </FormControl>

      <br />
      <Checkbox
        disabled={loading}
        checked={isPublic}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          setIsPublic(e.currentTarget.checked)
        }
        checkmarkType={STYLE_TYPE.toggle_round}
      >
        Allow others to scan this plant
      </Checkbox>
      <br />

      <FormControl label='Photos'>
        <PhotoInput
          value={photos}
          plantId={existing?.id}
          onChange={setPhotos}
          disabled={loading}
        />
      </FormControl>

      <hr />

      <Button onClick={save} isLoading={loading}>
        Save
      </Button>
      {existing && (
        <DeleteButton
          type='plant'
          plantID={existing.id}
          onDelete={onComplete}
        />
      )}
      {owners.length > 1 && (
        <>
          <br />
          <br />
          This plant is shared with{' '}
          {owners
            .filter((email) => email !== payload.email)
            .reduce<React.ReactNode>(
              (ac, email, i, a) => (
                <>
                  {ac}
                  {ac && (i + 1 === a.length ? ' and ' : ', ')}
                  <span style={{ color: theme.colors.primary }}>{email}</span>
                </>
              ),
              null,
            )}
        </>
      )}
      {dbDesc && (
        <>
          <hr />
          <h3>Infomation about species</h3>
          <ul>
            {dbDesc
              .split('. ')
              .filter((_, i) => i < 6)
              .map((text) => (
                <li key={text}>{text}</li>
              ))}
          </ul>
        </>
      )}
    </div>
  );
};
