import React, { useState, useEffect, useContext } from 'react';
import { StyledSpinnerNext as Spinner } from 'baseui/spinner';
import { SecurityContext } from 'wrappers/SecurityContext';
import { openIndexedDB } from 'hooks/fetchCache';
import classes from './LazyImage.module.scss';

async function fetchBlob(src: string, Kargo4: string): Promise<Blob> {
  const req = await fetch(`/api/record/img`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ Kargo4, id: src }),
  });
  const b64 = await req.text();
  const req2 = await fetch(b64);
  const blob = await req2.clone().blob();
  const arrBuf = await req2.clone().arrayBuffer();

  const cache = await openIndexedDB<[ArrayBuffer, string]>();
  cache.set(src, [arrBuf, blob.type]);

  return blob;
}

export async function getBlobUrl(src: string, Kargo4: string): Promise<string> {
  const cache = await openIndexedDB<[ArrayBuffer, string]>();
  const [arrBuf, type] = (await cache.get(src)) || [];
  if (arrBuf) {
    // there is something cached
    const blob = new Blob([arrBuf], { type });
    return URL.createObjectURL(blob);
  }
  const blob = await fetchBlob(src, Kargo4);
  return URL.createObjectURL(blob);
}

export const ImageWithDate: React.FC<{ src: string; dateTaken?: string }> = ({
  src,
  dateTaken,
}) => (
  <span className={classes.imagePreview}>
    {dateTaken && <span>{new Date(dateTaken).toLocaleDateString()}</span>}
    <img src={src} alt='Plant' />
  </span>
);

export const LazyImage: React.FC<{ src: string; dateTaken?: string }> = ({
  src,
  dateTaken,
}) => {
  const { Kargo4 } = useContext(SecurityContext);
  const [url, setUrl] = useState<string>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (url) return;
    getBlobUrl(src, Kargo4 as string)
      .then(setUrl)
      .catch(setError);
  }, [Kargo4, src, url]);

  if (error) {
    return (
      <span role='img' aria-label='Image failed to load'>
        📷
      </span>
    );
  }
  if (!url) return <Spinner />;

  return <ImageWithDate src={url} dateTaken={dateTaken} />;
};
