import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { StatefulInput } from 'baseui/input';
import { Button } from 'baseui/button';
import { Page } from 'components';
import classes from './Print.module.scss';
import { QR_PREFIX, BASE_URL } from '../../constants';

function uuidv4(): string {
  /* eslint-disable no-bitwise */
  return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, (c) =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16),
  );
}

const RandomQR: React.FC = () => {
  const uuid = uuidv4();
  return (
    <QRCode
      value={QR_PREFIX + uuid}
      style={{ padding: 6 }}
      imageSettings={{
        src: `${process.env.PUBLIC_URL}/logo512.png`,
        height: 30,
        width: 30,
      }}
    />
  );
};

export const Print: React.FC = () => {
  const [value, setValue] = useState<string>('');

  function generate(): void {
    window.print();
  }

  const isBad = Number.isNaN(+value) || +value > 200;

  return (
    <Page title='Print'>
      <div className={classes.noPrint}>
        How many QR codes should be generated?
        <br />
        <br />
        <StatefulInput
          placeholder='1'
          type='tel'
          error={value ? isBad : false}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            setValue(e.target.value)
          }
        />
        <div style={{ margin: 16 }}>
          <Button onClick={generate} disabled={isBad}>
            Print
          </Button>
        </div>
        If it&apos;s easier, you can print these codes from your computer by
        going to{' '}
        <a href={`${BASE_URL}/print`}>
          {BASE_URL.replace(/https?:\/\//, '')}/print
        </a>
      </div>
      <div className={classes.printOnly}>
        {!isBad &&
          new Array(+value)
            .fill(null)
            // eslint-disable-next-line react/no-array-index-key
            .map((_, i) => <RandomQR key={i} />)}
      </div>
    </Page>
  );
};
