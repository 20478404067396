import { useRef, useEffect } from 'react';
import { NDEFReader } from 'types/global';

export function useNFC(
  onComplete: (_: string) => void,
  setError: (_: Error) => void,
): void {
  const reader = useRef<NDEFReader>();

  useEffect(() => {
    if (!window.NDEFReader) return; // web nfc not supported

    if (!reader.current) {
      reader.current = new window.NDEFReader();
      reader.current.scan().catch(setError);

      reader.current.addEventListener('error', (e) => setError(e.message));

      reader.current.addEventListener('reading', ({ serialNumber }) => {
        onComplete(btoa(`nfc1|${serialNumber}`));
      });
    }
  }, [onComplete, setError]);
}
