import React, { useEffect, useContext } from 'react';
import { Notification, KIND } from 'baseui/notification';
import { PlantList } from 'types';
import { useFetch, getSpecific } from 'hooks/useFetch';
import { SecurityContext } from 'wrappers/SecurityContext';
import { CreatePlantForm } from './CreatePlantForm';

interface CreatePlantProps {
  uuid: string;
  onComplete: () => void;
  onDuplicate: (uuid: string) => void;
}
export const CreatePlant: React.FC<CreatePlantProps> = ({
  uuid,
  onComplete,
  onDuplicate,
}) => {
  const { Kargo4 } = useContext(SecurityContext);
  const [plantsList, error] = useFetch<PlantList>('/api/record');

  useEffect(() => {
    const existing = plantsList?.plants.find((p) => p.id === uuid);
    if (existing) onDuplicate(uuid);
    else {
      getSpecific(uuid, Kargo4 as string)
        .then((plant) => plant && onDuplicate(uuid))
        .catch(() => undefined);
    }
  }, [plantsList, uuid, onDuplicate, Kargo4]);

  if (error) {
    return <Notification kind={KIND.negative}>{error.message}</Notification>;
  }

  if (!plantsList) return <>Loading...</>;

  return <CreatePlantForm uuid={uuid} onComplete={onComplete} />;
};
