import React, { useRef, useState } from 'react';
import { Button } from 'baseui/button';
import { Modal, ModalBody, SIZE, ROLE } from 'baseui/modal';
import { ImageWithDate } from 'components';
import { PhotoList } from './formTypes';
import { DeleteButton } from './DeleteButton';
import classes from './FileUpload.module.scss';

const blobToBase64 = (blob: Blob | File): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.readAsDataURL(blob);
  });

export const blobUrlToBase64 = async (blobUrl: string): Promise<string> => {
  const blob = await fetch(blobUrl).then((r) => r.blob());
  const b64 = await blobToBase64(blob);
  return b64;
};

const ImageViewModal: React.FC<
  PhotoList & {
    onClose: () => void;
    onDelete: (urn: string) => void;
    plantId?: string;
  }
> = ({ blobUrl, plantId, urn, dateTaken, onClose, onDelete }) => {
  return (
    <Modal
      animate
      autoFocus
      isOpen
      closeable
      onClose={onClose}
      size={SIZE.default}
      role={ROLE.dialog}
      unstable_ModalBackdropScroll
    >
      <ModalBody>
        {dateTaken && (
          <span>
            Taken on:{' '}
            <strong>{new Date(dateTaken).toLocaleDateString()}</strong>
          </span>
        )}
        <br />
        <img src={blobUrl} alt='Plant Big' className={classes.full} />

        {urn && plantId && (
          <DeleteButton
            type='image'
            plantID={plantId}
            urn={urn}
            onDelete={onDelete}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

interface PhotoInputProps {
  value: PhotoList[];
  plantId?: string;
  onChange: (photos: PhotoList[]) => void;
  disabled?: boolean;
}
export const PhotoInput: React.FC<PhotoInputProps> = ({
  plantId,
  value,
  onChange,
  disabled,
}) => {
  const [popup, setPopup] = useState<PhotoList>();
  const ref = useRef<HTMLInputElement>(null);

  const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const blob = e.target.files?.[0];
    if (!blob) return; // nothing happened
    const blobUrl = URL.createObjectURL(blob);
    onChange([
      ...value,
      {
        urn: undefined,
        blobUrl,
        dateTaken: new Date(blob.lastModified).toISOString(),
      },
    ]);

    // now clear the input
    Reflect.set(e.target, 'value', null);
  };

  return (
    <>
      <input
        type='file'
        // capture='environment'
        ref={ref}
        className={classes.hidden}
        accept='image/*'
        onChange={onFileUpload}
      />
      {popup && (
        <ImageViewModal
          {...popup}
          plantId={plantId}
          onClose={(): void => setPopup(undefined)}
          onDelete={(urn): void => {
            setPopup(undefined);
            onChange(value.filter((x) => x.urn !== urn));
          }}
        />
      )}

      {value.map((v) => (
        <span
          key={v.blobUrl}
          role='button'
          tabIndex={0}
          onClick={(): void => setPopup(v)}
          className={classes.imagePreivewWrapper}
        >
          <ImageWithDate src={v.blobUrl} dateTaken={v.dateTaken} />
        </span>
      ))}
      <br />
      <Button disabled={disabled} onClick={(): void => ref.current?.click()}>
        Add Photo
      </Button>
    </>
  );
};
