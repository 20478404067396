const DB_NAME = 'PlantDB';
const VERSION = 1;

declare interface DB<T> {
  set: (id: string, data: T) => void;
  get: (id: string) => Promise<T | void>;
}

export async function openIndexedDB<T>(
  tableName = 'ObjectStore1',
): Promise<DB<T>> {
  if (!window.indexedDB) {
    throw new Error('Browser does not support IndexedDB');
  }

  return new Promise((resolve) => {
    const db = window.indexedDB.open(DB_NAME, VERSION);

    db.onupgradeneeded = (): void => {
      // this is called when the DB is first created.
      // Specified the prop "id" to be the index
      db.result.createObjectStore(tableName, {
        keyPath: 'id',
      });
    };

    db.onsuccess = (): void => {
      // this is called once the DB is opened
      const tx = db.result.transaction(tableName, 'readwrite');
      tx.oncomplete = (): void => db.result.close();

      const store = tx.objectStore(tableName);

      const get = (id: string): Promise<T> => {
        return new Promise((cb) => {
          const getData = store.get(id);
          getData.onsuccess = (): void => cb(getData.result?.data);
        });
      };

      const set = (id: string, data: T): void => {
        store.put({ id, data });
      };

      resolve({ get, set });
    };
  });
}
