import { useEffect, useState, useContext } from 'react';
import { Plant } from 'types';
import { openIndexedDB } from './fetchCache';
import { SecurityContext } from '../wrappers/SecurityContext';

export async function authedFetch<T>(
  path: string,
  Kargo4: string,
  options: Omit<RequestInit, 'body'> & { body?: object },
): Promise<T> {
  const { body, ...otherOpts } = options;
  const { error, ...data } = await fetch(path, {
    ...otherOpts,
    body: JSON.stringify({ Kargo4, ...body }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...otherOpts.headers,
    },
  }).then((r) => r.json());
  if (error) throw new Error(error);
  return data;
}

type ErrAndData<T> = [T | undefined, Error | undefined];

export function useFetch<T>(path: string): ErrAndData<T> {
  const [data, setData] = useState<T>();
  const [error, setError] = useState<Error>();

  const { Kargo4, logout } = useContext(SecurityContext);

  useEffect(() => {
    const controller = new AbortController();
    async function f(): Promise<void> {
      const cache = await openIndexedDB<T>();
      const cached = await cache.get(path);

      if (cached) {
        setData(cached);
        return;
      }

      // no cache so fetch data and update cache
      const d = await authedFetch<T>(path, Kargo4 as string, {
        method: 'POST',
        signal: controller.signal,
      });
      const cache2 = await openIndexedDB<T>();
      cache2.set(path, d);
      setData(d);
    }
    f().catch((ex) => {
      const err = ex instanceof Error ? ex : new Error(ex);
      if (err.message.includes('JsonWebTokenError')) {
        logout(); // logout; token has expired
        return;
      }
      setError(err);
    });
    return (): void => controller.abort();
  }, [Kargo4, path, logout]);

  return [data, error];
}

export const clearCache = (): void =>
  // eslint-disable-next-line no-void
  void window.indexedDB?.deleteDatabase('PlantDB');

export async function put<T extends unknown>(
  path: string,
  Kargo4: string,
  data: Record<string, unknown>,
): Promise<T> {
  const req = await fetch(path, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ Kargo4, ...data }),
  });
  const d = await req.json();
  if (d.error) throw new Error(d.error);

  (async (): Promise<void> => {
    // update cache in the background
    const newData = await authedFetch<T>(path, Kargo4, { method: 'POST' });
    const cache2 = await openIndexedDB();
    cache2.set(path, newData);
  })().catch(console.error);

  return d;
}

export async function getSpecific(
  id: string,
  Kargo4: string,
): Promise<Plant | undefined> {
  const { plant, error } = await fetch('/api/record/specific', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ Kargo4, id }),
  }).then((r) => r.json());
  if (error) throw new Error(error);
  return plant;
}
