import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Page } from 'components';
import { CreatePlant, Scan } from './components';

export const Add: React.FC = () => {
  const [to, setTo] = useState<string>();
  const [scanned, setScanned] = useState<string>();

  useEffect(() => {
    if (to && scanned) setScanned(undefined);
  }, [scanned, to]);

  if (to) return <Redirect push to={to} />;

  return (
    <Page title='Add a plant'>
      {scanned ? (
        <CreatePlant
          uuid={scanned}
          onComplete={(): void => setTo('/')}
          onDuplicate={(uuid): void => setTo(`/p/${uuid}`)}
        />
      ) : (
        <Scan onComplete={setScanned} />
      )}
    </Page>
  );
};
