import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { clearCache } from 'hooks/useFetch';
import { SecurityContext } from './SecurityContext';
import { BASE_URL } from '../constants';

export const SecurityWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [Kargo4, setKargo4] = useState(localStorage.Kargo4);

  useEffect(() => {
    localStorage.Kargo4 = Kargo4;
    if (!Kargo4) {
      // user did logout
      localStorage.clear();
      clearCache();
    }
  }, [Kargo4]);

  const payload = useMemo(() => {
    try {
      return JSON.parse(atob(Kargo4.split('.')[2]));
    } catch {
      return {};
    }
  }, [Kargo4]);

  const login = useCallback((): void => {
    const DEV_FLAG = process.env.NODE_ENV === 'development' ? '?dev=1' : '';
    window.open(`${BASE_URL}/api/federation${DEV_FLAG}`, '_blank');
    // June 2019: ^ setting target to _self or _blank works (but worse UX with blank?)
    window.addEventListener(
      'message',
      (event) => {
        if (typeof event.data !== 'string') return; // react devtools maybe
        const [sig, newToken] = event.data.split('::');
        if (sig === 'oof') setKargo4(newToken);
      },
      false,
    );
  }, []);

  return (
    <SecurityContext.Provider
      value={{
        payload,
        Kargo4,
        login,
        logout: (): void => setKargo4(undefined),
      }}
    >
      {children}
    </SecurityContext.Provider>
  );
};
